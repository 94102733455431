import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createMetaManager } from 'vue-meta'
import prismic from "./prismic/prismic";


createApp(App)
  .use(store)
  .use(router)
  .use(prismic)
  // .use(i18n)
  // .use(VueGtag, {
  //   bootstrap: false,
  //   params: {
  //     anonymize_ip: true
  //   }    
  // })
  .use(createMetaManager())
  .mount('#app');