<template>
  <section id="home" class="divider hidden-xs">
    <div class="container-fluid p-0" style="margin-top: -25px;">

      <!-- START REVOLUTION SLIDER 5.0.7 -->
      <div class="forcefullwidth_wrapper_tp_banner" id="rev_slider_home_forcefullwidth" style="position:relative;width:100%;height:auto;margin-top:0px;margin-bottom:0px"><div id="rev_slider_home_wrapper" class="rev_slider_wrapper fullwidthbanner-container" data-alias="news-gallery34" style="margin: 0px auto; background-color: rgb(255, 255, 255); padding: 0px; position: absolute; max-height: none; overflow: visible; height: 849px; width: 100%; left: 0px;">
        <!-- START REVOLUTION SLIDER 5.0.7 fullwidth mode -->
        <div id="rev_slider_home" class="rev_slider fullwidthabanner revslider-initialised tp-simpleresponsive rev_redraw_on_blurfocus" style="max-height: none; margin-top: 0px; margin-bottom: 0px; height: 100%;" data-version="5.0.7" data-slideactive="rs-1">
          <ul class="tp-revslider-mainul" style="visibility: visible; display: block; overflow: hidden; width: 100%; height: 100%; max-height: none;">
            <!-- SLIDE 1 -->
            <li data-index="rs-1" data-transition="slidingoverlayhorizontal" data-slotamount="default" data-easein="default" data-easeout="default" data-masterspeed="default" data-thumb="/images/bg/slide1.jpg" data-rotate="0" data-fstransition="fade" data-fsmasterspeed="1500" data-fsslotamount="7" data-saveperformance="off" data-title="Make an Impact" class="tp-revslider-slidesli active-revslide" style="width: 100%; height: 100%; overflow: hidden; z-index: 20; visibility: inherit; opacity: 1; background-color: rgba(255, 255, 255, 0);">
              <!-- MAIN IMAGE -->
              <div class="slotholder" style="position: absolute; top: 0px; left: 0px; z-index: 0; width: 100%; height: 100%; backface-visibility: hidden; transform: translate3d(0px, 25px, 0px); visibility: inherit; opacity: 1;"><!--Runtime Modification - Img tag is Still Available for SEO Goals in Source - <img src="images/bg/slide1.jpg" alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="10" class="rev-slidebg defaultimg" data-no-retina="">--><div class="tp-bgimg defaultimg " data-bgcolor="undefined" style="background-repeat: no-repeat; background-image: url(&quot;/images/bg/slide1.jpg&quot;); background-size: cover; background-position: center center; width: 100%; height: 100%; opacity: 1; visibility: inherit; z-index: 20;" src="/images/bg/slide1.jpg"></div></div>
              <!-- LAYERS -->
              <!-- LAYER NR. 1 -->
              <div class="tp-parallax-wrap" style="position: absolute; display: block; visibility: visible; left: 0px; top: 0px; z-index: 5;"><div class="tp-loop-wrap" style="position:absolute;display:block;;"><div class="tp-mask-wrap" style="position: absolute; display: block; overflow: visible;">
                <div class="tp-caption tp-shape tp-shapewrapper tp-resizeme rs-parallaxlevel-0 rs-toggle-content-active" id="slide-1-layer-1" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" data-width="full" data-height="full" data-whitespace="normal" data-transform_idle="o:1;" data-transform_in="opacity:0;s:1500;e:Power3.easeInOut;" data-transform_out="opacity:0;s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;" data-start="1000" data-basealign="slide" data-responsive_offset="on" style="z-index: 5; background-color: rgba(0, 0, 0, 0.35); border-color: rgb(0, 0, 0); visibility: inherit; transition: none 0s ease 0s; text-align: inherit; line-height: 23px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 14px; white-space: normal; min-height: 849px; min-width: 100%; opacity: 1; transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform-origin: 50% 50% 0px;"> 
                </div>
              </div></div></div>
              <!-- LAYER NR. 2 -->
              <div class="tp-parallax-wrap" style="position: absolute; display: block; background-color: #FFFFFF; visibility: visible; left: 20px; top: 720px; z-index: 6;">
                <div class="tp-loop-wrap" style="position:absolute;display:block;;">
                  <div class="tp-mask-wrap" style="position: absolute; display: block; overflow: hidden; transform: matrix(1, 0, 0, 1, 0, 0);">
                    <div class="tp-caption tp-resizeme text-white rs-parallaxlevel-0" id="slide-1-layer-2" data-x="['left','left','left','left']" data-hoffset="['50','50','50','30']" data-y="['top','top','top','top']" data-voffset="['120','100','70','90']" data-fontsize="['56','46','40','36']" data-lineheight="['70','60','50','45']" data-fontweight="['800','700','700','700']" data-width="['700','650','600','420']" data-height="none" data-whitespace="normal" data-transform_idle="o:1;" data-transform_in="y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;s:1500;e:Power3.easeInOut;" data-transform_out="auto:auto;s:1000;e:Power3.easeInOut;" data-mask_in="x:0px;y:0px;s:inherit;e:inherit;" data-mask_out="x:0;y:0;s:inherit;e:inherit;" data-start="1000" data-splitin="none" data-splitout="none" data-responsive_offset="on" style="z-index: 6; min-width: 700px; max-width: 900px; white-space: normal; visibility: inherit; transition: none 0s ease 0s; text-align: inherit; line-height: 70px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 800; font-size: 56px; min-height: 0px; max-height: none; opacity: 1; transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform-origin: 50% 50% 0px; min-width: 661px;">
                    
                      <div class="header-white" style="text-shadow: 0px 0px 12px #000000;">
                        <span style="font-size: 24px; margin-left: 10px; font-weight: 500;"><span style="color: red;">m</span>ore <span style="color: red;">p</span>assion.</span>
                        <span style="margin-left: 10px; font-weight: 500;"><span style="color: red;">m</span>ore <span style="color: red;">p</span>ower.</span>
                        <img style="margin-left: 10px;" src="/images/mpxm-logo-header.png" alt="MPXM Logo">
                      </div>
                <!-- <span style="text-shadow: 0px 0px 12px #000000; font-size: 15px;">
                  THINK <span style="color: #20c997;">GREEN</span>: Wir entwickeln zukunftsorientierte Fahrzeuge!
                </span> -->
                <!-- Take the <span class="text-theme-colored2" style="transition: none 0s ease 0s; text-align: inherit; line-height: 70px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 800; font-size: 56px;">world's</span> best <span class="text-theme-color-2" style="transition: none 0s ease 0s; text-align: inherit; line-height: 70px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 800; font-size: 56px;">Consulting</span> Site. -->
                </div>
              </div>
                </div>
              </div>

            </li>

          </ul>
          <div class="tp-bannertimer tp-bottom" style="height: 5px; background-color: rgb(166, 216, 236); width: 38.5556%; visibility: visible; transform: translate3d(0px, 0px, 0px);"></div>
        <div class="tp-loader off" style="display: none;"><div class="dot1"></div><div class="dot2"></div><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div><div class="tp-leftarrow tparrows zeus" style="top: 50%; transform: matrix(1, 0, 0, 1, 30, -35); left: 0px; visibility: hidden; opacity: 0;"><div class="tp-title-wrap">    <div class="tp-arr-imgholder" style="background-image: url(&quot;/images/bg/bg1.jpg&quot;);"></div> </div></div><div class="tp-rightarrow tparrows zeus" style="top: 50%; transform: matrix(1, 0, 0, 1, -100, -35); left: 100%; visibility: hidden; opacity: 0;"><div class="tp-title-wrap">    <div class="tp-arr-imgholder" style="background-image: url(&quot;/images/bg/bg2.jpg&quot;);"></div> </div></div><div class="tp-bullets metis horizontal nav-pos-hor-center nav-pos-ver-bottom nav-dir-horizontal" style="width: 160px; height: 50px; top: 100%; transform: matrix(1, 0, 0, 1, -80, -80); left: 50%; visibility: hidden; opacity: 0;"><div class="tp-bullet selected" style="left: 0px; top: 0px;"><span class="tp-bullet-img-wrap"><span class="tp-bullet-image" style="background-image: url(&quot;/images/bg/slide1.jpg&quot;);"></span></span></div><div class="tp-bullet" style="left: 55px; top: 0px;"><span class="tp-bullet-img-wrap"><span class="tp-bullet-image" style="background-image: url(&quot;/images/bg/bg2.jpg&quot;);"></span></span></div><div class="tp-bullet" style="left: 110px; top: 0px;"><span class="tp-bullet-img-wrap"><span class="tp-bullet-image" style="background-image: url(&quot;/images/bg/bg1.jpg&quot;);"></span></span></div></div></div>
      </div><div class="tp-fullwidth-forcer" style="width: 100%; height: 849px;"></div></div>


    </div>
  </section>  
</template>


<script>


</script>
