<template>

  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content}` : `${appName}` }}</template>
    <template v-slot:description="{ content }">{{ content }}</template>
  </metainfo>

  <div id="wrapper" class="clearfix">

    <Header /> 
    <div class="main-content">
      <router-view/>
    </div>
    
    <Footer />    

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { provide, defineAsyncComponent, computed } from 'vue'

import { useMeta } from 'vue-meta'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
  }, 
  setup() {

    // const { tm } = useI18n()

    // Global Vars
    const globalVars = {
      appName: 'mpx Mobility',
      appUrl: process.env.VUE_APP_URL,
      googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
      prismicApiEndpoint: process.env.VUE_APP_PRISMIC_API_ENDPOINT,
      prismicRepoName: process.env.VUE_APP_PRISMIC_REPO_NAME,
      env: process.env.VUE_APP_ENV,
    }
    provide('globalVars', globalVars);

    // Vue Meta
    const { meta } = useMeta({
      title: `Nachhaltige Karosserielösungen für CO2-freie Mobilität - mpx MOBILITY GmbH`,
      htmlAttrs: { lang: 'de-DE', amp: false },
      description: 'Entwickler von umweltfreundlichen Karosserien für erneuerbare Energien. Von Wasserstoff- bis Elektroantrieben - mpx MOBILITY GmbH ist Ihr Partner für nachhaltige Mobilität.',
      script: [],
    })    

  }
}

</script>