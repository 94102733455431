<template>
  
  <footer id="footer" class="footer" data-bg-img="/images/footer-bg.png" data-bg-color="#152029" style="background-image: url(&quot;/images/footer-bg.png&quot;); background-position: initial !important; background-size: initial !important; background-repeat: initial !important; background-attachment: initial !important; background-origin: initial !important; background-clip: initial !important; background-color: rgb(21, 32, 41) !important;">
    <div class="container pt-70 pb-40">
      <div class="row border-bottom">
        <div class="col-sm-6 col-md-3">
          <div class="widget dark">
            <img class="mt-5 mb-20" alt="" src="/images/logo-white-footer4.png">
            <p>mpx MOBIITY GmbH, Spretistr. 7b, 81927 München</p>
            <ul class="list-inline mt-5">
              <li class="m-0 pl-10 pr-10"> <i class="fa fa-phone text-theme-colored2 mr-5"></i> <a class="text-gray" href="#">+49 160 7483700</a> </li>
              <li class="m-0 pl-10 pr-10"> <i class="fa fa-envelope-o text-theme-colored2 mr-5"></i> <a class="text-gray" href="#">contact@mpxm.de</a> </li>
              <li class="m-0 pl-10 pr-10"> <i class="fa fa-globe text-theme-colored2 mr-5"></i> <a class="text-gray" href="#">www.mpxm.de</a> </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-6 col-md-3">
          <div class="widget dark">
            <h4 class="widget-title">Links</h4>
            <ul class="list angle-double-right list-border">
              <!-- <li><a href="page-about-style1.html">Imp</a></li> -->
              <li v-for="item in navigation" :key="item.key">
                <!-- <a href="page-about-style1.html">{{ item.name }}</a> -->
                <router-link :to="{ name: item.href }">{{ item.name }}</router-link>
              </li>
              <li>
                <a href="/Datenschutz">Datenschutz</a>
              </li>
            </ul>

          </div>
        </div>
        <div class="col-sm-6 col-md-3">
          <div class="widget dark">
            <h4 class="widget-title">Social Media</h4>
            <ul class="icon-bordered icon-sm list angle-double-right list-border">
              <li><a target="_blank" href="https://www.linkedin.com/company/mpxm/"><i class="fa fa-linkedin"></i> Linkedin</a></li>
            </ul>
          </div>
        </div>
        <div class="col-sm-6 col-md-3">
          <div class="widget dark">
            <h4 class="widget-title line-bottom-theme-colored-2">Über uns</h4>
            <div class="opening-hours">
              Die mpx MOBILITY GmbH engagiert sich für die Entwicklung umweltfreundlicher Mobilität durch nachhaltige und recyclingfreundliche Karosserielösungen. Ihr Fokus liegt auf der Entwicklung von Karosseriearchitekturen für erneuerbare Energien, einschließlich Wasserstoff- und Brennstoffzellentechnologie sowie Elektroantrieben.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom bg-theme-colored-transparent-5">
      <div class="container pt-0 pb-30">
        <div class="row">
          <div class="col-md-6">
            <p class="font-11 text-black-777 m-0">Copyright {{ year }} {{ globalVars.appName }}. All Rights Reserved</p>
          </div>
        </div>
      </div>
    </div>
  </footer>

</template>


<script>
import { ref, inject } from 'vue'

export default {
  setup() {
    
    const globalVars = inject('globalVars')

    var dateObj = new Date();
    var year = dateObj.getUTCFullYear();    

    const navigation = [
      { name: 'Impressum', key: 'Impressum', href: 'Impressum' },
      // { name: 'AGB', key: 'Agb', href: 'Agb' },
      // { name: 'Datenschutz', key: 'Datenschutz', href: 'Datenschutz' },
    ]

    return {
      globalVars,
      year,
      navigation,
    }

  },
}
</script>
