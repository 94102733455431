<template>
  
  <header id="header" class="header modern-header modern-header-theme-colored2">

    <div class="header-middle p-0 bg-light xs-text-center">
      <div class="container pt-30 pb-30">
        <div class="row">
          <div class="col-xs-12 col-sm-4 col-md-3">
            <div class="widget sm-text-center">
              <i class="fa fa-envelope text-theme-colored2 font-32 mt-5 mr-sm-0 sm-display-block pull-left flip sm-pull-none"></i>
              <a href="#" class="font-12 text-gray text-uppercase">Kontaktieren Sie uns</a>
              <h5 class="font-13 text-black m-0"> contact@mpxm.de</h5>
            </div>
          </div>
          <div class="col-xs-12 col-sm-4 col-md-6">
            <div class="widget text-center">
              <a class="" href="/"><img src="/images/logo-wide4.png" alt=""></a>
            </div>
          </div>
          <div class="col-xs-12 col-sm-4 col-md-3">
            <div class="widget sm-text-center">
              <i class="fa fa-building-o text-theme-colored2 font-32 mt-5 mr-sm-0 sm-display-block pull-left flip sm-pull-none"></i>
              <a href="#" class="font-12 text-gray text-uppercase">mpx MOBIITY GmbH</a>
              <h5 class="font-13 text-black m-0">Tel.: +49 160 7483 700</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="header-nav">
      <div class="header-nav-wrapper navbar-scrolltofixed" style="z-index: 1111; position: absolute; top: 0px;">
        <div class="container">
          <nav id="menuzord" class="menuzord green menuzord-responsive"><a href="javascript:void(0)" class="showhide" style="display: none;"><em></em><em></em><em></em></a>
            <ul class="menuzord-menu onepage-nav menuzord-indented scrollable" style="max-height: 400px;">
              <li class="current active"><a href="#home">Home</a></li>
              <li class=""><a href="#about">About</a></li>
              <li class=""><a href="#services">Services</a></li>
              <li><a href="#project">project</a></li>
              <li class=""><a href="#team">Team</a></li>
              <li class=""><a href="#analysis">Analysis</a></li>
              <li class=""><a href="#blog">Blog</a></li>
              <li class=""><a href="#contact">Contact</a></li>
            <li class="scrollable-fix"></li></ul>
          </nav>
        </div>
      </div>
    </div> -->
  </header>

</template>