<template>

<HomeBanner />
<p>&nbsp;</p>
<div class="container">
  <div v-if="state === 'success'">
    <PrismicRichText :field="document.data.content" />
    <br />
  </div>


  <h2 id="termin"><strong>Hier können Sie einen Termin mit uns vereinbaren</strong></h2>
  <div class="calendly-inline-widget" data-url="https://calendly.com/calendly-mpxm/30min?hide_event_type_details=1&primary_color=ff0000" style="min-width:320px;height:700px;"></div>

</div>

      

</template>



<script>

import { usePrismicDocumentByUID } from "@prismicio/vue";
import { useMeta } from 'vue-meta'
import { onMounted, watch, ref } from 'vue'
import { useStore } from 'vuex'

import HomeBanner from '@/components/HomeBanner.vue'

export default {
  name: 'Home',
  components: {
    HomeBanner
  }, 
  setup() {  

    onMounted(() => { 
      window.scrollTo(0, 0);
    });

    const { meta } = useMeta({ })
    const store = useStore()

    const { data: document, state, error, refresh } = usePrismicDocumentByUID("page", "home");    
    
    watch(() => state.value, (first, second) => {
      if (first === 'success') {
        meta.title = document.value.data.meta_title;
			  meta.description = document.value.data.meta_description;
        store.commit('pageTitle/setTitle', document.value.data.meta_title)
      }
    });   

    meta.script = [
      { src: 'https://assets.calendly.com/assets/external/widget.js', async: true, defer: true }
    ];
  

    return {
      document,
      state
    }

  }
}
</script>
